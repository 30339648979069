import React from 'react';
import { Flex, Box, Image, Text, Button, Link } from 'rebass/styled-components';
import styled from 'styled-components';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';

import LogoWhite from '../../assets/images/logoWhite.svg';

const StyledLink = styled(Link)`
  font-family: Poppins;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
`;

const StyledCopyright = styled(Text)`
  font-weight: 500;
  text-align: center;
  color: #ffffff;
`;

const Footer = () => {
  const data_content = useStaticQuery(
    graphql`
      query MyQueryFooter {
        contentfulAoNLandingPage {
          contactUsEmail
        }
      }
    `
  );

  return (
    <Flex
      flexWrap="wrap"
      justifyContent="center"
      alignContent="center"
      backgroundColor="darkRed"
    >
      <Box width={1} backgroundColor="red" py={6}>
        <Flex justifyContent="space-around" mx={2}>
          <GatsbyLink to="/">
            <Image
              src={LogoWhite}
              width={['160px', '189px']}
              alt="Autoimmune Or Not Logo"
            ></Image>
          </GatsbyLink>
          <Link
            href={`mailto:${data_content.contentfulAoNLandingPage.contactUsEmail}`}
          >
            <Button
              py={1}
              variant="bFooter"
              width={['130px', '257px']}
              height={['30px', '44px']}
              fontSize={[4, 7, 7]}
            >
              contact us
            </Button>
          </Link>
        </Flex>
      </Box>
      <Box
        width={1}
        textAlign="left"
        mt={10}
        px={[3, 30, 50]}
        fontSize={[4, 7]}
      >
        <StyledLink
          href="https://www.progentec.com/terms-conditions"
          target="_blank"
        >
          TERMS AND CONDITIONS
        </StyledLink>
      </Box>
      <Box width={1} textAlign="left" py={5} px={[3, 30, 50]} fontSize={[4, 7]}>
        <StyledLink
          href="https://www.progentec.com/privacy-policy"
          target="_blank"
        >
          PRIVACY POLICY
        </StyledLink>
      </Box>
      <StyledCopyright pb={4} pt={14} fontSize={[0, 2]}>
        Copyright Progentec Diagnostics, Inc. 2020 All Rights Reserved.
      </StyledCopyright>
    </Flex>
  );
};

export default Footer;

import React from 'react';
import ReactHelmet from 'react-helmet';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import favicon_32 from '../../assets/images/favicon_32@3x.png';
import metaImage from '../../assets/images/aon-metaImage@2x.png';

const title = `Autoimmune Or Not`;
const description = `23,500,000+ people are battling an autoimmune disease. Check your symptoms with the CSQ, a validated health measurement.`;

const Helmet = ({ theme = {} }) => (
  <ReactHelmet htmlAttributes={{ lang: 'en' }}>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="shortcut icon" href={favicon_32} />
    <meta name="theme-color" content={theme.background} />
    <meta name="image" content={favicon_32} />
    <meta itemProp="name" content={title} />
    <meta itemProp="description" content={description} />
    <meta itemProp="image" content={favicon_32} />
    <meta name="og:title" content={title} />
    <meta name="og:description" content={description} />
    <meta name="og:image" content={metaImage} />
    <meta name="og:site_name" content={title} />
    <meta name="og:locale" content="en_US" />
    <meta name="og:type" content="website" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon_32} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon_32} />
  </ReactHelmet>
);

Helmet.propTypes = {
  // eslint-disable-next-line
  theme: PropTypes.object
};

export default withTheme(Helmet);

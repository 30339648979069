import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ScrollingProvider } from 'react-scroll-section';
import Helmet from './Helmet';
import Header from './Header';
import Footer from './Footer';
import { Flex, Box } from 'rebass/styled-components';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import theme from '../../theme';

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: inherit;
    }

  body {
    box-sizing: border-box;
    margin: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    line-height: 1.31;
    letter-spacing: 0.4px;
    color: #161f26;
  }
  
  a {
    color: #ff5e63;
    text-decoration: underline;
  }

  .linksWhite a {
    color: #FFFFFF;
    text-decoration: underline;
  }


`;

const Layout = ({ children, onClick, logoHeader, mainHeader }) => {
  return (
    <Fragment>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <ScrollingProvider>
          <Helmet />
          <Box id="top" bg="lightgrey" width={1} minHeight={'990px'}>
            <Header
              onClick={onClick}
              logoHeader={logoHeader}
              mainHeader={mainHeader}
            />
            <Flex flexDirection="column" alignItems="center" mx={2}>
              {children}
            </Flex>
          </Box>
          <Footer />
        </ScrollingProvider>
      </ThemeProvider>
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;

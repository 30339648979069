import React, { useState, useEffect } from 'react';
import { Flex, Image, Box, Button, Heading } from 'rebass/styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { STORAGE_KEYS } from '../resources/constants';
import circuitHero from '../../assets/images/circuitHero_desktop@1.5x.svg';

const StyledHeader = styled(Heading)`
  font-family: Poppins;
  font-size: 28px;
  font-weight: bold;
  color: #2f261e;
`;

const StyledSubHeader = styled(Heading)`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  color: #2f261e;
`;

const Header = ({ onClick, logoHeader, mainHeader }) => {
  const [quizComplete, setQuizComplete] = useState(null);

  const data_content = useStaticQuery(
    graphql`
      query MyQueryHeader {
        contentfulAoNLandingPage {
          id
          headerLogo {
            file {
              url
            }
          }
          headerTitle
          subtitle
          button {
            text
            url
            urlInternal
          }
          secondaryButton {
            text
            url
            urlInternal
          }
        }
      }
    `
  );

  const handleTrackCustomEvent = actionName => {
    trackCustomEvent({
      category: 'homePage',
      action: actionName
    });
  };

  useEffect(() => {
    setQuizComplete(localStorage.getItem(STORAGE_KEYS.QUIZ_COMPLETE));
  }, []);

  return (
    <Flex flexWrap="wrap" justifyContent="center" alignContent="center">
      {logoHeader && (
        <Box width={1}>
          <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
            <GatsbyLink to="/">
              <Image
                src={data_content.contentfulAoNLandingPage.headerLogo.file.url}
                alt="Autoimmune Or Not Logo"
                onClick={onClick}
                width={'182px'}
                mt={11}
                mb={9}
              ></Image>
            </GatsbyLink>
          </Flex>
        </Box>
      )}

      {mainHeader && (
        <Box width={1}>
          <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
            <Box width={1}>
              <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
                <Image
                  src={circuitHero}
                  alt="circuit-hero"
                  height="225px"
                  sx={{
                    borderRadius: 8,
                    objectFit: 'cover',
                    height: '100%'
                  }}
                ></Image>
              </Flex>
            </Box>
            <Box width={1}>
              <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
                <Flex
                  px={[8, 6, 4]}
                  flexWrap="wrap"
                  maxWidth="550px"
                  justifyContent="flex-start"
                >
                  <StyledHeader mt={5} mb={2}>
                    {data_content.contentfulAoNLandingPage.headerTitle}
                  </StyledHeader>
                  <StyledSubHeader>
                    {data_content.contentfulAoNLandingPage.subtitle}
                  </StyledSubHeader>
                  {!quizComplete && (
                    <GatsbyLink
                      to={
                        data_content.contentfulAoNLandingPage.button.urlInternal
                          ? data_content.contentfulAoNLandingPage.button
                              .urlInternal
                          : data_content.contentfulAoNLandingPage.button.url
                      }
                      target={
                        data_content.contentfulAoNLandingPage.button.urlInternal
                          ? ''
                          : '_blank'
                      }
                    >
                      <Button
                        variant="bRed"
                        my={5}
                        width="228px"
                        onClick={() =>
                          handleTrackCustomEvent(
                            data_content.contentfulAoNLandingPage.button.text
                          )
                        }
                      >
                        {data_content.contentfulAoNLandingPage.button.text}
                      </Button>
                    </GatsbyLink>
                  )}
                  {quizComplete && (
                    <GatsbyLink
                      to={
                        data_content.contentfulAoNLandingPage.secondaryButton
                          .urlInternal
                          ? data_content.contentfulAoNLandingPage
                              .secondaryButton.urlInternal
                          : data_content.contentfulAoNLandingPage
                              .secondaryButton.url
                      }
                      target={
                        data_content.contentfulAoNLandingPage.secondaryButton
                          .urlInternal
                          ? ''
                          : '_blank'
                      }
                    >
                      <Button
                        variant="bRed"
                        my={5}
                        width="228px"
                        onClick={() =>
                          handleTrackCustomEvent(
                            data_content.contentfulAoNLandingPage
                              .secondaryButton.text
                          )
                        }
                      >
                        {
                          data_content.contentfulAoNLandingPage.secondaryButton
                            .text
                        }
                      </Button>
                    </GatsbyLink>
                  )}
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default Header;

module.exports = {
  colors: {
    red: '#ff5e63',
    darkRed: '#d04c50',
    white: '#ffffff',
    lightgrey: '#f2f4f9',
    black: '#161f26'
  },
  space: [
    0,
    4,
    8,
    16,
    18,
    20,
    24,
    28,
    30,
    32,
    40,
    50,
    60,
    64,
    120,
    128,
    188,
    256,
    512
  ],
  fontSizes: [8, 10, 12, 14, 16, 18, 20, 24, 26, 28, 30, 32, 34, 36],
  fonts: {
    poppins: 'Poppins'
  },
  fontWeights: {},
  lineHeights: {},
  shadows: {},
  variants: {},
  text: {},
  links: {
    main: {
      color: '#CD1852',
      textDecoration: 'underline'
    }
  },
  buttons: {
    bRed: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: '500',
      textAlign: 'center',
      color: '#ffffff',
      width: '228px',
      height: '50px',
      borderRadius: '25px',
      boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#ff5e63',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#ff5e63'
      },
      '&:focus': {
        backgroundColor: '#ff5e63',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#dbe2e7',
        cursor: 'not-allowed',
        opacity: '1'
      },
      cursor: 'pointer'
    },

    bDarkRed: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: '500',
      textAlign: 'center',
      color: '#ffffff',
      width: '228px',
      height: '50px',
      borderRadius: '25px',
      boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#d04c50',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#d04c50'
      },
      '&:focus': {
        backgroundColor: '#d04c50',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#dbe2e7',
        cursor: 'not-allowed',
        opacity: '1'
      },
      cursor: 'pointer'
    },

    bBlack: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: '500',
      textAlign: 'center',
      color: '#ffffff',
      width: '228px',
      height: '50px',
      borderRadius: '25px',
      boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#161f26',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#161f26'
      },
      '&:focus': {
        backgroundColor: '#161f26',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#dbe2e7',
        cursor: 'not-allowed',
        opacity: '1'
      },
      cursor: 'pointer'
    },

    blightGrey: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: '600',
      textAlign: 'center',
      color: '#161f26',
      width: '202px',
      height: '50px',
      borderRadius: '25px',
      boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#f1f4f8',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#f1f4f8'
      },
      '&:focus': {
        backgroundColor: '#f1f4f8',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#f1f4f8',
        cursor: 'not-allowed',
        opacity: '1'
      },
      cursor: 'pointer'
    },

    bWhite: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: '500',
      textAlign: 'center',
      color: '#161f26',
      width: '228px',
      height: '50px',
      borderRadius: '25px',
      boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#fffff',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#ffffff'
      },
      '&:focus': {
        backgroundColor: '#ffffff',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#ffffff',
        cursor: 'not-allowed',
        opacity: '1'
      },
      cursor: 'pointer'
    },

    bGrey: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: '500',
      textAlign: 'center',
      color: '#ffffff',
      width: '228px',
      height: '50px',
      borderRadius: '25px',
      boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#dbe2e7',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#dbe2e7'
      },
      '&:focus': {
        backgroundColor: '#dbe2e7',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#dbe2e7',
        cursor: 'not-allowed',
        opacity: '1'
      },
      cursor: 'pointer'
    },

    bFooter: {
      fontFamily: 'Poppins',
      fontSize: '23.5px',
      fontWeight: '500',
      textAlign: 'center',
      color: '#ffffff',
      width: '257px',
      height: '44px',
      borderRadius: '4.4px',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#d04c50',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#d04c50'
      },
      '&:focus': {
        backgroundColor: '#d04c50',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#dbe2e7',
        cursor: 'not-allowed',
        opacity: '1'
      },
      cursor: 'pointer'
    },

    timeSchedule: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '1.14',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#161f26',
      width: '78px',
      height: '44px',
      borderRadius: '4px',
      border: 'solid 1px #eceff3',
      backgroundColor: '#ffffff',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#ff5e63',
        color: '#ffffff'
      },
      '&:focus': {
        backgroundColor: '#ff5e63',
        color: '#ffffff',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#dbe2e7',
        cursor: 'not-allowed',
        opacity: '1'
      },
      cursor: 'pointer'
    },

    whiteSchedule: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '1.13',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#161f26',
      width: '250px',
      height: '50px',
      borderRadius: '25px',
      boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#ffffff',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#ffffff'
      },
      '&:focus': {
        backgroundColor: '#ffffff',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#dbe2e7',
        cursor: 'not-allowed',
        opacity: '1'
      },
      cursor: 'pointer'
    },

    redSchedule: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '1.13',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#FFFFFF',
      width: '250px',
      height: '50px',
      borderRadius: '25px',
      boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#d04c50',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#d04c50'
      },
      '&:focus': {
        backgroundColor: '#d04c50',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#dbe2e7',
        cursor: 'not-allowed',
        opacity: '1'
      },
      '&:target': {
        backgroundColor: '#d04c50'
      },
      cursor: 'pointer'
    },

    lightRed: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '1.14',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#FFFFFF',
      width: '156px',
      height: '30px',
      borderRadius: '25px',
      boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#ff5e63',
      '&:hover': {
        opacity: '0.5'
      },
      '&:active': {
        backgroundColor: '#ff5e63'
      },
      '&:focus': {
        backgroundColor: '#ff5e63',
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#dbe2e7',
        cursor: 'not-allowed',
        opacity: '1'
      },
      '&:target': {
        backgroundColor: '#d04c50'
      },
      cursor: 'pointer'
    }
  }
};

export const API = {
  service: `${process.env.API_URL}/service`,
  scheduling: 'scheduling',
  patients: 'patients',
  quizzes: 'quizzes',
  billing: 'billing',
  packages: 'packages'
};

export const STORAGE_KEYS = {
  ID: 'ID',
  QUIZ_ID: 'QUIZ_ID',
  QUIZ_RESPONSES_SECTION: 'QUIZ_RESPONSES_SECTION',
  QUIZ_COMPLETE: 'QUIZ_COMPLETE',
  PACKAGE_DATA: 'PACKAGE_DATA'
};

export const DEV = {
  ZIP_CODE: '20500',
  PROVIDER_ID: '143231',
  LOCATION_ID: '138'
};

export const STEPS = {
  ENTER_YOUR_ZIP_CODE: 1,
  CONFIRM_CLINIC: 2,
  CONFIRM_DOCTOR: 3,
  SELECT_A_DATE: 4,
  PATIENT_DETAILS: 5
};

export const STEPS_CHECKOUT = {
  PAYMENT_METHOD: 1,
  CREDIT_CARD: 2,
  CONFIRM_PAYMENT: 3
};
